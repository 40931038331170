import {defineStore} from 'pinia'
import {NetworkService} from "~/utils/NetworkService";
import { useGlobalEvent, useNotificationEvent} from "~/composables/useEventBus";
import {type Club} from "~/models/club";
import {type AddStudentIntoClubRb} from "~/models/request/addStudentIntoClubRb";
import {ClubType} from "~/utils/enums/ClubType";
import {type GetClubsByStudentIdRb} from "~/models/request/getClubsByStudentIdRb";
import {ClubStatus} from "~/utils/enums/ClubStatus";
import {type GetClubDetailsByClubIdRb} from "~/models/request/getClubDetailsByClubIdRb";
import {type AddUpdateClubRb} from "~/models/request/addUpdateClubRb";
import {type DeleteStudentFromClubRb} from "~/models/request/deleteStudentFromClubRb";
import {type ClubRegQuestionAnswerRb} from "~/models/request/ClubRegQuestionAnswerRb";
import {type ClubRegistrationAnswer} from "~/models/ClubRegistrationAnswer";
import {type LoadAllAnswersOfAClubRb} from "~/models/request/loadAllAnswersOfAClubRb";
import {type NotificationDetails} from "~/models/notificationDetails";
import {NotificationType} from "~/utils/enums/NotificationType";
import {type GetClubStatusesByClubIdsAndStudentIdsRb} from "~/models/request/getClubStatusesByClubIdsAndStudentIdsRb";
import {type StudentClubStatus} from "~/models/studentClubStatus";
import type {AddAnnouncementRb, AddUpdateAnnouncementRb} from "~/models/request/announcements/addUpdateAnnouncementRb";
import type {GetAnnouncementBySenderRb} from "~/models/request/announcements/getAnnouncementBySenderRb";
import type {GetAnnouncementByAnnouncementIdRb} from "~/models/request/announcements/getAnnouncementByAnnouncementIdRb";
import type {GetAnnouncementByCompanyRb} from "~/models/request/announcements/getAnnouncementByCompanyRb";
import type {
    DeleteAnnouncementByAnnouncementIdRb
} from "~/models/request/announcements/deleteAnnouncementByAnnouncementId";

export const announcementStore = defineStore({
    id: 'announcement-store',
    persist: true,
    state: () => {
        return {}
    },
    actions: {
        createAnnouncement: async function (addAnnouncementPayload: AddAnnouncementRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/TextMessage/createText';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, addAnnouncementPayload, null, "addAnnouncementIntoServer" + new Date().getMilliseconds() + "_")
            if(Result?.id){
                return Result;
            }
        },
        getAnnouncementsBySenderId: async function (getAnnouncementsRb: GetAnnouncementBySenderRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/TextMessage/GetAllThreadsByUserId';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, getAnnouncementsRb, null, "getAnnouncementsList" + new Date().getMilliseconds() + "_")
            if(Result?.length > 0){
                return Result;
            }
        },
        getAnnouncementByAnnouncementId: async function (getAnnouncementRb: GetAnnouncementByAnnouncementIdRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/TextMessage/getThreadDetails';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, getAnnouncementRb, null, "getAnnouncementDetails" + new Date().getMilliseconds() + "_")
            if(Result){
                return Result;
            } else {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Announcement not found issue',
                    message: 'Announcement is not found.',
                })
            }
        },
        getAnnouncementByCompanyId: async function (getAnnouncementRb: GetAnnouncementByCompanyRb){
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/TextMessage/GetAllThreadsByCompanyId';
            const ns = new NetworkService();
            try {
                let {Result} = await ns.post$fetch(url, getAnnouncementRb, null, "getAnnouncementsByCompanyId" + new Date().getMilliseconds() + "_")
                if(Result){
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Announcement Issue',
                        message: 'Announcement is not found.',
                    })
                }
            } catch(err) {
                console.log("Error from getAnnouncementByCompanyId: " + err)
            }
        },
        updateAnnouncementByAnnouncementId: async function (updateAnnouncementRb: AddUpdateAnnouncementRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/TextMessage/updateConversationThread';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, updateAnnouncementRb, null, "updateAnnouncementInServer" + new Date().getMilliseconds() + "_")
            if(Result) {
                return Result;
            }
        },
        deleteAnnouncementByAnnouncementId: async function (deleteAnnouncementRb: DeleteAnnouncementByAnnouncementIdRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/TextMessage/deleteConversationThread';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, deleteAnnouncementRb, null, "deleteAnnouncementInServer" + new Date().getMilliseconds() + "_")
            if(Result) {
                return Result;
            }
        },
    },
    getters: {
    }
})
